export const jobNewHtml = `
<div id="manual-upload-dialog" class="mdc-dialog z-15">
  <div class="mdc-dialog__container">
    <div class="mdc-dialog__surface"
         role="alertdialog"
         aria-modal="true"
         aria-labelledby="upload-dialog"
         aria-describedby="file-upload-dialog">
      <h2 id="dialog-title" class="mdc-dialog__title">Upload a new file</h2>
      <div id="dialog-content" class="mdc-dialog__content">

        <div class="content-row info">
          <i class="material-icons info-text" >info</i>
          <span class="info-text">After the upload, the file automatic processing takes less than 1 hour.</span>
        </div>

        <div class="content-row">
          <div id="select-template-area" class="mdc-select">
            <div class="mdc-select__anchor dialog-select form-field">
              <i class="mdc-select__dropdown-icon"></i>
              <div class="mdc-select__selected-text"></div>
              <span class="mdc-floating-label">Template</span>
              <div class="mdc-line-ripple"></div>
            </div>

            <div class="mdc-select__menu mdc-menu mdc-menu-surface form-field">
              <ul id="select-template" class="mdc-list">
                <li class="mdc-list-item mdc-list-item--selected" data-value="" aria-selected="true"></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="content-row">
          <input type="file" id="input-file" style="display:none;" /><!-- actual form file field -->
          <div class="mdc-text-field form-field" id="file-text-field">
            <input class="mdc-text-field__input" readonly  id="input-file-name" />
            <div class="mdc-line-ripple"></div>
            <label for="input-file-name" class="mdc-floating-label">File</label>
          </div>
          <button id="btn-select-file" class="mdc-button mdc-button--raised">
            <span class="mdc-button__label">Browse</span>
          </button>
        </div>

        <div class="content-row-state">
          <div id="state-show-area">
            <span>state area</span>
          </div>
          <div id="progress-bar-area">
              <div role="progressbar" class="mdc-linear-progress">
                  <div class="mdc-linear-progress__buffering-dots"></div>
                  <div class="mdc-linear-progress__buffer"></div>
                  <div id="main-progress-bar" class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                  </div>
                  <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                  </div>
                </div>
          </div>
        </div>
      </div>

      <footer class="mdc-dialog__actions">
          <button type="button" id="btn-abort" class="mdc-button mdc-dialog__button">
              <span class="mdc-button__label dialog-btn-text">Abort</span>
          </button>
          <button type="button" id="btn-retry" class="mdc-button mdc-dialog__button">
            <span class="mdc-button__label dialog-btn-text">Retry</span>
          </button>
          <button type="button" id="btn-cancel" class="mdc-button mdc-dialog__button">
            <span class="mdc-button__label dialog-btn-text">Cancel</span>
          </button>
          <button type="button" id="btn-file-upload" class="mdc-button mdc-dialog__button">
            <span class="mdc-button__label dialog-btn-text">Upload</span>
          </button>
      </footer>
    </div>
  </div>
  <div class="mdc-dialog__scrim"></div>
</div>
`;
