export var noJobs = `
<div id="empty-state-jobs" class="emptyStateContainer">
  <img class="emptyStateImg" src="resources/no-jobs-found.svg"/>
  <p class="emptyStateText">No jobs found</p>
  <p class="emptyStateHint">Upload a file to start.</p>
</div>
`;

export var jobsTable = `
<div id="jobs-table" class="mdc-data-table">
  <table class="mdc-data-table__table" aria-label="Jobs List">
    <thead class="z-5">
      <tr class="mdc-data-table__header-row">
        <th class="mdc-data-table__header-cell cols-5__secondary" role="columnheader" scope="col">Uploaded By</th>
        <th class="mdc-data-table__header-cell cols-5__primary" role="columnheader" scope="col">File</th>
        <th class="mdc-data-table__header-cell cols-5__tertiary" role="columnheader" scope="col">Status
          <i  title="1. Created&#13;&#10;2. Uploaded&#13;&#10;3. Converted&#13;&#10;4. Transformed&#13;&#10;5. Done&#13;&#10;Failed"
              class="material-icons inline-icon">info</i></th>
        <th class="mdc-data-table__header-cell cols-5__tertiary" role="columnheader" scope="col">
          Last Update
          <i class="material-icons table-header-text inline-icon">arrow_downward</i>
        </th>
      </tr>
    </thead>
  </table>
  <footer id="jobs-pagination">
    <button id="btn-previous" class="mdc-button pagination-disabled" disabled>
      <div class="mdc-button__ripple"></div>
      <i class="material-icons mdc-button__icon" aria-hidden="true">chevron_left</i>
      <span class="mdc-button__label">Previous</span>
    </button>
    <span id=pagination-number></span>
    <button id="btn-next" class="mdc-button">
      <div class="mdc-button__ripple"></div>
      <span class="mdc-button__label">Next</span>
      <i class="material-icons mdc-button__icon" aria-hidden="true">chevron_right</i>
    </button>
  </footer>
</div>
<!-- Loading Indicator -->
<div id="content-indicator" class="indicator">
  <div class="indicator-container">
    <div class="spinner-container">
      <div class="spinner-layer spinner-layer--blue">
        <div class="spinner-clipper spinner-clipper--left">
          <div class="spinner-circle"></div>
        </div>
        <div class="spinner--patch">
          <div class="spinner-circle"></div>
        </div>
        <div class="spinner-clipper spinner-clipper--right">
          <div class="spinner-circle"></div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
