import { jobNewHtml } from './template';
import { createJob } from '../data';

import { MDCDialog } from '@material/dialog/index';
import { MDCSelect } from '@material/select/index';
import { MDCTextField } from '@material/textfield/index';

import './style.scss';

var STATE = {
  INITIAL   : 1,
  UPLOADING : 2,
  ERROR     : 3,
  FAILED    : 4
};
var uploadDialog = undefined;
var selectTemplate = undefined;
var fileField = undefined;
var templates = undefined;

function init() {
  $("body").append(jobNewHtml);
  uploadDialog = new MDCDialog(document.querySelector("#manual-upload-dialog"));
  selectTemplate = new MDCSelect(document.querySelector("#select-template-area"));
  fileField = new MDCTextField(document.querySelector("#file-text-field"));
  uploadDialog.listen('MDCDialog:opened', function() {
    uploadDialog.focusTrap_.deactivate();// do not focus automatically
  });
  uploadDialog.listen('MDCDialog:closed', function() {
    controlDialogState(STATE.INITIAL);
  });

  // stop closing dialog when click parent windows
  uploadDialog.scrimClickAction = "";
  // stop closing dialog when type esc
  uploadDialog.escapeKeyAction = "";

  // dialog listeners
  $("#btn-select-file").on("click", function() {
    $("#input-file").click();
  });

  $("#input-file").on("change", function() {
    fileField.value = $(this).val().split("\\").pop();
  });

  $("#btn-abort").on("click", function() {
    uploadDialog.close();
  });

  $("#btn-retry").on("click", function() {
    controlDialogState(STATE.UPLOADING);
  });

  $("#btn-cancel").on("click", function() {
    uploadDialog.close();
  });

  $("#btn-file-upload").on("click", function() {
    controlDialogState(STATE.UPLOADING);
  });
}

export function renderNewJobDialog($templates) {
  renderSelectTemplate($templates);
  controlDialogState(STATE.INITIAL);
  uploadDialog.open();
}

export function onNewJobClose(callback) {
  uploadDialog.listen('MDCDialog:closing', function() {
    callback();
  });
}

function controlDialogState(state) {
  controlSelectFields(state);
  controlFooterBtn(state);
  controlProgressBar(state);
  controlStateArea(state);
  switch(state) {
    case STATE.UPLOADING:
        createJobAndUpload();
        break;
    default:
        break;
  }
}

function controlSelectFields(state) {
  switch(state) {
    case STATE.INITIAL:
      selectTemplate.disabled = false;
      fileField.disabled = false; /* already readonly in html but for style alignment with selectTemplate */
      $("#btn-select-file").prop("disabled", false);
      selectTemplate.selectedIndex = -1;
      $("#input-file").val(null);
      fileField.value = "";
      break;
    case STATE.INVALID:
      selectTemplate.disabled = false;
      fileField.disabled = false;
      $("#btn-select-file").prop("disabled", false);
      break;
    case STATE.UPLOADING:
      selectTemplate.disabled = true;
      fileField.disabled = true;
      $("#btn-select-file").prop("disabled", true);
    case STATE.FAILED:
      selectTemplate.disabled = true;
      fileField.disabled = true;
      $("#btn-select-file").prop("disabled", true);
      break;
  }
}

function controlFooterBtn(state) {
  switch(state) {
    case STATE.INITIAL:
    case STATE.INVALID:
        $("#btn-abort").css("visibility","hidden");
        $("#btn-retry").css("visibility","hidden");
        $("#btn-file-upload").prop("disabled", false);
        $("#btn-file-upload span").removeClass("disable-text");
        $("#btn-cancel").prop("disabled", false);
        $("#btn-cancel span").removeClass("disable-text");
        break;
    case STATE.UPLOADING:
        $("#btn-abort").css("visibility","hidden");
        $("#btn-retry").css("visibility","hidden");
        $("#btn-file-upload").prop("disabled", true);
        $("#btn-file-upload span").addClass("disable-text");
        break;
    case STATE.FAILED:
        $("#btn-abort").css("visibility","visible");
        $("#btn-retry").css("visibility","visible");
        $("#btn-file-upload").prop("disabled", true);
        $("#btn-file-upload span").addClass("disable-text");
        $("#btn-cancel").prop("disabled", true);
        $("#btn-cancel span").addClass("disable-text");
        break;
  }
}

function controlProgressBar(state) {
  switch(state) {
    case STATE.INITIAL:
        //hide progress bar
        $(".mdc-linear-progress").addClass("mdc-linear-progress--closed");
        $(".mdc-linear-progress").css("display", "none");
        break;
    case STATE.INVALID:
        //hide progress bar
        $(".mdc-linear-progress").addClass("mdc-linear-progress--closed");
        $(".mdc-linear-progress").css("display", "none");
        break;
    case STATE.UPLOADING:
        //initialize progress bar
        $("#main-progress-bar").css("transform","scaleX(0)");
        //show progress bar
        $(".mdc-linear-progress").removeClass("mdc-linear-progress--closed");
        $(".mdc-linear-progress").css("display", "inline-block");
        break;
    case STATE.FAILED:
        //initialize progress bar
        $("#main-progress-bar").css("transform","scaleX(0)");
        //show progress bar
        $(".mdc-linear-progress").removeClass("mdc-linear-progress--closed");
        $(".mdc-linear-progress").css("display", "inline-block");
      break;
  }
}

function controlStateArea(state) {
  switch(state) {
    case STATE.INITIAL:
        $("div#state-show-area span").text("");
        break;
    case STATE.INVALID:
        $("div#state-show-area span").css("color","red");
        $("div#state-show-area span").text("All fields are mandatory");
        break;
    case STATE.UPLOADING:
        $("div#state-show-area span").css("color","#5978f2");
        $("div#state-show-area span").text("Uploading");
        break;
    case STATE.FAILED:
        $("div#state-show-area span").css("color","red");
        $("div#state-show-area span").text("Failed!");
        break;
  }
}

function renderSelectTemplate(tpl) {
  templates = tpl;
  templates.sort(function(a, b) {
    return a.ID < b.ID ? -1 : 1;
  });

  var options = $.map(templates, function (value, key) {
    var $option = $('<li>')
      .attr({
              "data-value": key,
              "class": "mdc-list-item",
              "role": "option"
            })
      .text(value.TemplateName);
    return $option;
  });

  if (options.length == 0) {
    options.push('<li class="mdc-list-item" disabled selected value>No templates found</li>');
  }
  $("#select-template").empty().append(options);
}

function createJobAndUpload() {
  if(!isFormValid()){
    controlDialogState(STATE.INVALID);
    return;
  }

  var template = templates[selectTemplate.value];
  var fileObject = $("#input-file")[0].files[0];
  var job = {
    "airportGroup": template.AirportGroup,
    "templateId": template.ID,
    "fileName": fileField.value,
    "fileMimeType": fileObject.type
  }

  createJob(job, upload, function() {
    console.log("Failed to create job.");
    controlDialogState(STATE.FAILED);
  });
}

function renderUploadProgress(achieved, total) {
  var progress = achieved / total;
  $("#main-progress-bar").css("transform","scaleX(" + progress + ")");
}

function upload(job) {
  var url = job['uploadRequest']['url'];
  var fields = job['uploadRequest']['fields'];
  var fileObject = $("#input-file")[0].files[0];
  var fdata = createUploadFormData(fields, fileObject);

  $.ajax({
       type: "POST",
       url: url,
       data: fdata,
       processData: false,
       contentType: false,
       xhr : function() {
         // control progress bar
         var XHR = $.ajaxSettings.xhr();
         if(XHR.upload){
             XHR.upload.addEventListener('progress', function(p) {
                renderUploadProgress(p.loaded, p.total);
             }, false);
         }
         return XHR;
       }
   }).done(function() {
     uploadDialog.close();
   }).fail(function() {
     console.log("Failed to upload file.");
     controlDialogState(STATE.FAILED);
   });
}

function createUploadFormData(fields, fileObject) {
  var formData = new FormData();
  for (var key in fields) {
    formData.append(key, fields[key]);
  }
  formData.append('file', fileObject);
  return formData;
}

function isFormValid(){
  var notValidEls = [];

  if (selectTemplate.value === "") {
    notValidEls.push({"id": "select-template-area"});
  }
  if($("#input-file").val().length === 0){
    notValidEls.push({"id": "input-file"});
  }

  return notValidEls.length === 0;
}

init();
