export var dialogHtml = `
<div id="job-details-dialog" class="mdc-dialog z-15">
  <div class="mdc-dialog__container">
    <div class="mdc-dialog__surface"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="job-details"
          aria-describedby="details-about-a-job">

      <!-- Loading Indicator -->
      <!-- from http://jsfiddle.net/meyxLdbu/1/ -->
      <div id="dialog-indicator" class="indicator">
        <div class="indicator-container">
          <div class="spinner-container">
            <div class="spinner-layer spinner-layer--blue">
              <div class="spinner-clipper spinner-clipper--left">
                <div class="spinner-circle"></div>
              </div>
              <div class="spinner--patch">
                <div class="spinner-circle"></div>
              </div>
              <div class="spinner-clipper spinner-clipper--right">
                <div class="spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 id="dialog-title" class="mdc-dialog__title details-title">
        <p id="dialog-title-text" class="text-over-hidden"></p>
        <button id="close-btn" class="mdc-icon-button material-icons" data-mdc-dialog-action="close" tabindex="-1" data-mdc-dialog-initial-focus>close</button>
      </h2>

      <div class="mdc-dialog__content">
        <div id="job-details-info">
          <div class="content-row">
            <div class="content-label">
              <span title="Template">Template</span>
            </div>
            <div id="template-value" class="content-value"></div>
          </div>
          <div class="content-row">
            <div class="content-label">
              <span title="Status">Status</span>
            </div>
            <div id="status-value" class="content-value"></div>
          </div>
          <div class="content-row">
            <div class="content-label">
              <span title="Creation Date">Creation Date</span>
            </div>
            <div id="create-date-value" class="content-value"></div>
          </div>
          <div class="content-row">
            <div class="content-label">
              <span title="uploaded By">Uploaded By</span>
            </div>
            <div id="uploaded-by-value" class="content-value capitalize"></div>
          </div>
          <div class="content-row">
            <div class="content-label">
              <span title="ID">ID</span>
            </div>
            <div id="id-value" class="content-value"></div>
          </div>
          <div id="details-list-section">
            <table id="job-details-list" class="job-details-list">
              <thead>
                <tr>
                  <th class="event_time">Event Time</th>
                  <th class="result">Result</th>
                  <th class="type">Type</th>
                  <th class="name">Name</th>
                <tr/>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mdc-dialog__scrim"></div>
</div>
`;

export var noJobEventsHtml = `
<div id="events-empty-state">
  <img class="no-jobs-img" src="resources/no-jobs-found.svg"/><br/>
  <span>No job details found.</span>
</div>
`;
