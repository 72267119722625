/**
 @param ISO8601 string
 Returns a string with localized datetime and formatted as follows:
  * Today display time => HH:mm
  * Current year => MMM dd
  * more than  2 months MMM dd
 */
export function formatToReadableDate(iso8601) {
    var dt = new Date(iso8601);
    var now = new Date();
    var locale = getUserLocale();
    var options;
    if (dt.getDate() == now.getDate()
        && dt.getMonth() == now.getMonth()
        && dt.getFullYear() == now.getFullYear()) {
      options = { hour: 'numeric', minute: 'numeric' };
    } else if (dt.getFullYear() == now.getFullYear()) {
      options = { month: 'short', day: 'numeric' };
      if (locale.startsWith("ja")) { // Workaround for missing "月": https://github.com/Microsoft/ChakraCore/issues/5048
        options = { month: 'long', day: 'numeric' }; // Returns “M月D日“, other bug used as a workaround
      }
    } else {
      options =  { year: 'numeric', month: 'numeric', day: 'numeric' };
    }
    return dt.toLocaleString(locale, options);
  }

export function formatToFullDate(iso8601) {
    var dt = new Date(iso8601);
    return dt.toLocaleString();
}

export function formatToFullTime(iso8601) {
    var dt = new Date(iso8601);
    return dt.toLocaleTimeString();
}

function getUserLocale() {
  return navigator.language; // ko for IE < 11
}

export function formatUserName(text) {
  if (text == null) {
    return "Unknown";
  }
  else {
    return text.replace(".", " ");
  }
}
