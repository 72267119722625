import auth from './Auth';
import { CONFIG } from './config';
import { floatingLabel } from 'material-components-web';

var user = undefined;
var $templates = undefined;
var $jobs = undefined;
var $detailedJobs = {};

class User {
  constructor(userInfo, fail) {
    this.email = userInfo.email;
    this.userId = userInfo.preferred_username;
    this.name = this._extractName(userInfo.email);
    if(userInfo["custom:department"] === undefined){
      this.adDepartment = null;
    }
    else{
      this.adDepartment = this._extractDep(userInfo["custom:department"]);
    }
    this._transformDep(fail);
  }

  _extractName(email) {
    var localPart = email.split("@")[0];
    return localPart.split(".").join(" ");
  }

  _extractDep(customDep) {
    let parentDep = customDep.split('部')[0] + '部';
    return parentDep;
  }

  _transformDep(fail) {
    let options = {
      'airportGroup' : 'KAP',
      'keyAdDepartmentName' : this.adDepartment
    }
    const setDepartment = (department) => {
      this.department = department;
    }
    auth.getToken(getDepartment, options, setDepartment, fail);
  }
}

function setAuthorization(xhr, accToken) {
  xhr.setRequestHeader('Authorization', 'Bearer ' + accToken);
}

export function getTemplateName(id) {
  var foundList = $.grep($templates, function(template) {
    return template.ID === id;
  });
  if (foundList.length < 1) {
    return id;
  } else if (foundList.length >= 1) {
    return foundList[0].TemplateName;
  }
}

/******** Internal API to access data *********/
export const FetchMode = { // cache is JS heap + local stoer if any
  DEFAULT: 1, // cache then from remote server
  CACHE_ONLY: 2, // if not in cache return undefined
  FORCE_REMOTE: 3 // always load resource from server
};

export function fetchUser(succeed, fail) {
  if (user !== undefined) {
    return succeed(user);
  }
  //TODO Improvement: get user from local storage (also need to save user to local storage)
  auth.getToken(loadUser, {}, succeed, fail);
}

export function fetchTemplates(mode, succeed, fail) {
  let options = {'airportGroup':'KAP'};
  if(user.department !== null && CONFIG.ADMIN_NUMBER.indexOf(user.userId) === -1){
    // check unset department or whether use is admin or not.
    options['department'] = user.department;
  }

  switch(mode) {
    case FetchMode.DEFAULT: {
      if ($templates === undefined) {
        auth.getToken(getTemplates, options, succeed, fail);
      } else {
        succeed($templates);
      }
      break;
    }
    case FetchMode.CACHE_ONLY: {
      return $templates;
      break;
    }
    case FetchMode.FORCE_REMOTE: {
      auth.getToken(getTemplates, options, succeed, fail);
      break;
    }
    default: {
      console.log(mode + " fetch mode not supported!");
    }
  }
}

export function fetchJobs(mode, paginationParam, succeed, fail) {
  if (paginationParam === undefined) {
    paginationParam = {
        limit: CONFIG.NUM_DISPLAY_JOBS,
        offset: 0
    }
  }
  switch(mode) {
    case FetchMode.DEFAULT: {
      auth.getToken(getJobs, paginationParam, succeed, fail);
      break;
    }
    case FetchMode.CACHE_ONLY: {
      return $jobs;
      break;
    }
    case FetchMode.FORCE_REMOTE: {
      auth.getToken(getJobs, paginationParam, succeed, fail);
      break;
    }
    default: {
      console.log(mode + " fetch mode not supported!");
    }
  }
}

export function fetchJob(mode,  id, succeed, fail) {
  switch(mode) {
    case FetchMode.DEFAULT: {
      var cachedJob = $detailedJobs[id];
      if (cachedJob === undefined) {
        var options = { "jobId": id };
        auth.getToken(getJob, options, succeed, fail);
      } else {
        succeed(cachedJob);
      }
      break;
    }
    case FetchMode.CACHE_ONLY_SYNC: {
      return $detailedJobs[id];
      break;
    }
    case FetchMode.FORCE_REMOTE: {
      var options = { "jobId": id };
      auth.getToken(getJob, options, succeed, fail);
      break;
    }
    default: {
      console.log(mode + " fetch mode not supported!");
    }
  }
}

export function createJob(job, succeed, fail) {
  auth.getToken(postJob, job, succeed, fail);
}

/******** Requests to data API *********/

function loadUser(token, options, succeed, fail) {
  $.ajax({
    url: "https://" + CONFIG.COGNITO_DOMAIN + "/oauth2/userInfo",
    dataType: "json",
    beforeSend: function (xhr) { setAuthorization(xhr, token); }
  })
  .done(function(data) {
    user = new User(data, fail);
    succeed(user);
  })
  .fail(function(status, error) {
    console.error("Failed to get signed in user: " + status);
    fail("Failed to load user");
  });
}

function getJobs(session, options, succeed, fail) {
  $.ajax({
    type:'GET',
    url: CONFIG.API_ROOT_URL + "/jobs",
    dataType: "json",
    beforeSend: function (xhr) { setAuthorization(xhr, session); },
    data: options
  })
  .done(function(data) {
    $jobs = data;
    succeed($jobs);
  })
  .fail(function(status, error) {
    console.error("Failed to get jobs: " + status, error);
    fail("Failed to load jobs");
  });
}

function getTemplates(session, options, succeed, fail) {
  var templatesUrl = CONFIG.API_ROOT_URL + "/templates"
  $.ajax({
    type: "GET",
    url: templatesUrl,
    data: options,
    dataType: "json",
    beforeSend: function (xhr) { setAuthorization(xhr, session); }
  }).done(function(data){
    $templates = data;
    succeed($templates);
  }).fail(function(jqXHR, status, error){
    console.error("Failed to get templates: " + status, error);
    fail("Failed to load templates");
  });
}

function getJob(session, options, succeed, fail) {
  $.ajax({
    url: CONFIG.API_ROOT_URL + "/jobs/" + options.jobId,
    dataType: "json",
    beforeSend: function (xhr) { setAuthorization(xhr, session); }
  })
  .done(function(data) {
    $detailedJobs[options.jobId] = data;
    succeed(data);
  })
  .fail(function(status, error) {
    console.error("Failed to get job details: " + status, error);
    fail("Failed to load job");
  });
}

function postJob(session, job, succeed, fail) {
  $.ajax({
        type: "POST",
        url: CONFIG.API_ROOT_URL + "/jobs",
        dataType: "json",
        beforeSend: function (xhr) { setAuthorization(xhr, session); },
        data: JSON.stringify(job)
  }).done(function(data) {
    succeed(data);
  }).fail(fail);
}

function getDepartment(session, options, succeed, fail) {
  let departmentsURL = CONFIG.API_ROOT_URL + "/departments"
  $.ajax({
    type: "GET",
    url: departmentsURL,
    data: options,
    dataType: "json",
    beforeSend: function (xhr) { setAuthorization(xhr, session); },
    async: false
  }).done(function(data){
    let department;
    if(data.length === 0){
      console.warn('cannot read department mapping');
      department = null;
    }
    else if (data.length === 1) {
      department = data[0]['Department'];
    }
    else {
      console.warn('Failed to get jobs: got multiple departments');
      department = null;
    }
    succeed(department);
  }).fail(function(jqXHR, status, error){
    console.error("Failed to get department: " + status, error);
    fail("Failed to load department");
  });
}
