import { formatToReadableDate, formatToFullDate, formatUserName } from '../tools';
import { noJobs, jobsTable } from './template';
import { renderJob } from '../jobDetails/index';
import { paginateJobs } from '../app';
import { CONFIG } from '../config';
import noJobsImage from '../resources/no-jobs-found.svg';
import './style.scss'

var paginationState = {};
var errorFunction = undefined;

function createCellContent(text) {
    return $('<span title=' + text + ' />').text(text).addClass('overflowing')
}

function buildJobsTable($job_contents, $templates) {
  var $jobs = $job_contents['jobs']
  var $table = $(jobsTable);
  var $tbody = $table.children('table').append('<tbody />').children('tbody');
  var $pagination = $table.children('footer');
  var $btn_previous = $pagination.find('#btn-previous');
  var $btn_next = $pagination.find('#btn-next');

  $.each($jobs, function(i, job) {
    $tbody.append('<tr class="mdc-data-table__row"/>').children('tr:last')
            .attr('title', "ID: " + job["id"])
          .append($('<td/>')
            .addClass("mdc-data-table__cell cols-5__secondary overflowable capitalize")
            .append(createCellContent(formatUserName(job["userName"]))))
          .append($('<td/>')
              .addClass("mdc-data-table__cell cols-5__primary overflowable")
              .append(createCellContent(job["fileName"])))
          .append($('<td/>')
              .addClass("mdc-data-table__cell cols-5__tertiary overflowable")
              .append(createCellContent(job["state"])))
          .append($('<td/>')
              .text(formatToReadableDate(job["lastUpdatedDate"]))
              .attr('title', formatToFullDate(job["lastUpdatedDate"]))
              .addClass("mdc-data-table__cell cols-5__tertiary"));
  });

  // Change information in pagination area
  $pagination.find('span#pagination-number').text(paginationState.first_num + '-'+ paginationState.last_num +' of ' + paginationState.total_jobs);
  $btn_previous.prop('disabled', paginationState.first_page);
  $btn_next.prop('disabled', paginationState.last_page);
  (paginationState.first_page) ? $btn_previous.addClass('pagination-disabled') : $btn_previous.removeClass('pagination-disabled');
  (paginationState.last_page) ? $btn_next.addClass('pagination-disabled') : $btn_next.removeClass('pagination-disable');

  return $table;
}

export function renderJobs($job_contents, $templates, error) {
  errorFunction = error;
  updatePaginationState($job_contents['offset'], $job_contents['estimatedTotalLength']);
  if ($job_contents['jobs'].length > 0) {
    return buildJobsTable($job_contents, $templates);
  } else {
    var $emptyState = $(noJobs);
    $emptyState.find(".emptyStateImg").html(noJobsImage);
    return $emptyState;
  }
}

export function attachJobsControls() {
    $("#jobs-table tbody tr").click(function () {
      renderJob($(this).attr("title").replace("ID: ", ""), errorFunction);
    });
    $("#jobs-pagination #btn-previous").click(function () {
      var previous_page = {
        limit: Number(CONFIG.NUM_DISPLAY_JOBS),
        offset: paginationState.offset - Number(CONFIG.NUM_DISPLAY_JOBS)
      }
      paginateJobs(previous_page);
    });
    $("#jobs-pagination #btn-next").click(function () {
      var next_page = {
        limit: Number(CONFIG.NUM_DISPLAY_JOBS),
        offset: paginationState.offset + Number(CONFIG.NUM_DISPLAY_JOBS)
      }
      paginateJobs(next_page);
    })
}

function updatePaginationState(offset, total_item) {
  var num_display_jobs = Number(CONFIG.NUM_DISPLAY_JOBS);

  paginationState = {
    "offset": offset,
    "limit": num_display_jobs,
    "first_num": offset + 1,
    "last_num": ((offset + num_display_jobs) < total_item) ? offset + num_display_jobs : total_item,
    "total_jobs" :total_item,
    "first_page": (offset === 0) ? true : false,
    "last_page": ((offset + num_display_jobs) < total_item) ? false : true
  }
}
