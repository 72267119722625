import { CognitoAuth } from 'amazon-cognito-auth-js/es';
import { CONFIG } from './config';

class Auth {

  constructor() {
    this._authData = {
      ClientId : CONFIG.CLIENT_ID,
      AppWebDomain : CONFIG.COGNITO_DOMAIN,
      TokenScopesArray : CONFIG.SCOPES,
      RedirectUriSignIn : CONFIG.SIGNIN_CALLBACK_URL,
      RedirectUriSignOut : CONFIG.SIGNOUT_CALLBACK_URL,
      UserPoolId : CONFIG.USERPOOL_ID
    };
    this._cAuth = new CognitoAuth(this._authData);
    this._cAuth.useCodeGrantFlow();
  }

  // Set the function to call after the asynchronous functions `getSession` or `parseCognitoWebResponse`
  _setCallback(succeed, fail) {
    this._cAuth.userhandler = {
    	onSuccess: function(result) {
        succeed(result);
    	},
    	onFailure: function(err) {
        onFailureFilter(err, fail);
    	}
    };
  }

  _hasAuthorizationCode() {
    // URL object is not used for IE11 compatibility
    var params = window.location.search.split("&");
    for (var i = 0; i < params.length; i++) {
      if (params[i].startsWith("?code=")) {
        return true;
      }
    }
    return false;
  }

  signIn(success, fail) {
    this._setCallback(
      success,
      function(err) {
        fail("Sign in failed: " + err);
      }
    );

    if (this._hasAuthorizationCode()) {
      this._cAuth.parseCognitoWebResponse(window.location.href); // executes auth.userhandler
      // Remove the authorization code from URL and history
      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      this._cAuth.getSession(); // executes auth.userhandler
    }
  }

  /**
  * Retrieve a valid access token asynchronously.
  * Then executes the method that fetch data from an API.
  */
  getToken(fetch, fetchOptions, succeed, fail) {
    this._setCallback(
      function(session) {
        fetch(session.getAccessToken().getJwtToken(), fetchOptions, succeed, fail);
      },
      function() {
        fail("Failed to refresh credentials. Log out and retry.")
      });

    this._cAuth.getSession(); // Asynchronous. Ensure access token is valid then execute fetch
  }

  // Clear cache and redirect to sign-in screen
  signOut() {
    this._cAuth.signOut(); // Asynchronous
  }

}

// Set-up as a singleton
const auth = new Auth();
Object.freeze(auth);

export default auth;

// Process auth.userhandler onFailure
// - Handle when error related to authentication/session
// - Otherwise execute `fail` callback passed as param
function onFailureFilter(err, fail) {
  const REFRESH_TOKEN_EXPIRED_ERROR = "invalid_grant";
  try {
    var error = JSON.parse(err);
    if (error !== undefined && error["error"] == REFRESH_TOKEN_EXPIRED_ERROR) {
      auth.signOut(); // Does not seem to require to have a valid token
    } else {
      fail(err);
    }
  } catch (e) {
    console.log("Failed to process AJAX error_response=" + err + "; process_error=" + e.message);
    fail(e);
  }
}
