export const CONFIG = __APP_CONFIG__;

CONFIG.SIGNIN_CALLBACK_URL = createSignInUrl();
CONFIG.SIGNOUT_CALLBACK_URL = createSignOutUrl();

function createSignInUrl() {
  return getRootUrl();
}

function createSignOutUrl() {
  return getRootUrl();
}

function getRootUrl() {
  var url = window.location.href;
  var cleaned = url.split("#")[0].split("?")[0]; // remove query and fragment
  if (CONFIG.ENV === "local") {
    var rootEndIndex = 3;
  } else {
    var rootEndIndex = 4;
  }
  var root = cleaned.split('/').slice(0, rootEndIndex).join('/');
  return root + '/';
}
