import * as mdc from 'material-components-web';
import { MDCSnackbar } from '@material/snackbar/index';

import { CONFIG } from './config';
import auth from './Auth';
import { fetchUser, fetchTemplates, fetchJobs, FetchMode } from './data';
import { renderJobs, attachJobsControls } from './jobList/index';
import { renderNewJobDialog, onNewJobClose } from './jobNew/index';

import './app.scss';

/* IE11 support */
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

function initHeader(env) {
  if (env != 'prd'){
    $('<div class="env-banner">' + env + '</div>').appendTo("body");
  }
}

/******** Display *********/
export function error(message) {
  console.log(message);
  controlMainState("FAILED");
  var snackbar = new MDCSnackbar(document.querySelector("#error-snackbar"));
  snackbar.labelText = message;
  snackbar.open();
  $("#retry-data-btn").on("click", function() {
    controlMainState("INITIATING");
    initData();
  })
}

function controlMainState(state) {
  switch(state) {
    case "INITIATING":
      // main indicator already displayed by default
      $('#content-indicator').show(); // in case of retry
      break;
    case "REFRESHING":
      $('#refresh-content-btn').prop("disabled", true);
      $('#refresh-content-icon').addClass('fa-spin');
      // show loading in contents area
      $('#jobs-table').hide();
      $('#content-indicator').show();
      fetchJobs(FetchMode.FORCE_REMOTE, undefined, handleJobs, error);
      break;
    case "PAGINATING":
      // show loading in contents area
      $('#jobs-table').hide();
      $('#content-indicator').show();
      break;
    case "FAILED":
    case "DONE":
      $('#refresh-content-icon').removeClass('fa-spin');
      $('#refresh-content-btn').prop("disabled", false);
      $('#content-indicator').hide();
      break;
  }
}

function handleTemplates($templates) {
  var $jobs = fetchJobs(FetchMode.CACHE_ONLY);
  if ($jobs !== undefined) {
    displayJobs($jobs, $templates);
  }
}

export function handleJobs($jobs) {
  var $templates = fetchTemplates(FetchMode.CACHE_ONLY);
  if ($templates !== undefined) {
    displayJobs($jobs, $templates);
  }
}

function displayJobs($jobs, $templates) {
  $("#content").html(renderJobs($jobs, $templates, error));
  attachJobsControls();
  controlMainState("DONE");
}

export function paginateJobs(next_page) {
  controlMainState("PAGINATING");
  fetchJobs(FetchMode.DEFAULT, next_page, handleJobs, error);
}

/******** Authentication  *********/
function handleSignedIn(session) {
  if (session) {
    const handleFetch = (user) => {
      setSignedInView(user);
      fetchTemplates(FetchMode.DEFAULT, handleTemplates, error);
      fetchJobs(FetchMode.DEFAULT, undefined, handleJobs, error);
    }
    fetchUser(handleFetch, error);
    // fetchUser(setSignedInView, error);
    // fetchTemplates(FetchMode.DEFAULT, handleTemplates, error);
    // fetchJobs(FetchMode.DEFAULT, undefined, handleJobs, error);
  }
}

/******** User  *********/
function setSignedInView(user) {
  $("#username").text(user.name);
  $("#menuUsername").text(user.name);
  $("#email").text(user.email);

  $("#profileButton").on("click", function() {
    const menu = new mdc.menu.MDCMenu(document.querySelector('#profileMenu'));
    menu.open = !menu.open;
  });
  $("#signOut").on("click", function() {
    setSignedOutView();
    auth.signOut();
  });
  $("#profileButton").prop('disabled', false);
}

function setSignedOutView() {
  $("#username").text("Signing out...");
  $("#menuUsername").text("");
  $("#email").text("");
  $("#profileButton").prop('disabled', true);
}

/******** Web Page Init Entry Point *********/
$(document).ready(function() {
  initHeader(CONFIG.ENV);

  auth.signIn(handleSignedIn, error);

  // Main listeners
  onNewJobClose(function() {
    controlMainState("REFRESHING");
  });
  $("#primary-action-btn").on("click", function() {
    renderNewJobDialog(fetchTemplates(FetchMode.CACHE_ONLY));
  });

  $("#refresh-content-btn").on("click", function() {
    controlMainState("REFRESHING");
  });

  $("#helpButton").on("click", function() {
    const helpMenu = new mdc.menu.MDCMenu(document.querySelector('#helpMenu'));
    helpMenu.open = !helpMenu.open;
  });
});
