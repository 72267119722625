import { MDCDialog } from '@material/dialog/index';

import { dialogHtml, noJobEventsHtml } from './template';
import { FetchMode, fetchJob, getTemplateName } from '../data';
import { formatToFullDate, formatToFullTime, formatUserName } from '../tools';
import './style.scss';

var jobDetailsDialog = undefined;
var errorFunction = undefined;

function createContentWithTooltip(text, title) {
    if(title == null){
        return $('<span/>').attr("title", text).text(text);
    }
    else {
        return $('<span/>').attr("title", title).text(text);
    }
}

function init() {
  $("body").append(dialogHtml); //TODO #content dependency should be injected
}

export function renderJob(jobId, error) {
  errorFunction = error;
  jobDetailsDialog = new MDCDialog(document.querySelector("#job-details-dialog"));

  jobDetailsDialog.listen('MDCDialog:opened', function() {
    jobDetailsDialog.focusTrap_.deactivate();// do not focus automatically
  });

  jobDetailsDialog.listen('MDCDialog:closed', function() {
    removeJobInformation();
  });

  // stop closing dialog when type esc
  jobDetailsDialog.escapeKeyAction = "";

  removeJobInformation();
  jobDetailsDialog.open();
  setLoading();
  fetchJob(FetchMode.FORCE_REMOTE, jobId, displayJob, function(msg) {
    error(message);
    jobDetailsDialog.close();
  }); // if the user close and open the dialog, the data is refreshed
}

function buildJobEventTable(events) {
  var $tbody=$('<tbody>');
  $.each(events, function(i, jobDetails) {
    $tbody.append('<tr/>').children('tr:last')
          .append($('<td class="event_time"/>')
              .addClass("cols-5__primary")
              .append(createContentWithTooltip(formatToFullTime(jobDetails["createdDate"]), formatToFullDate(jobDetails["createdDate"]))))
          .append($('<td class="result"/>')
              .addClass("cols-5__primary")
              .append(createContentWithTooltip(jobDetails.result)))
          .append($('<td class="type"/>')
              .addClass("cols-5__secondary")
              .append(createContentWithTooltip(jobDetails.processType)))
          .append($('<td class="name"/>')
              .addClass("cols-5__tertiary")
              .append(createContentWithTooltip(jobDetails.processName)))
  });
  return $tbody;
}

function buildEmptyState() {
  var $emptyState = $(noJobEventsHtml);
  return $emptyState;
}

function displayJob(jobDetails) {
  removeLoading();
  $("#job-details-dialog #dialog-title-text").text(jobDetails.fileName)
  $("#job-details-dialog #dialog-title-text").attr("title", jobDetails.fileName);
  $("div#template-value").append(createContentWithTooltip(getTemplateName(jobDetails.templateId)));
  $("div#status-value").append(createContentWithTooltip(jobDetails.state));
  $("div#create-date-value").append(createContentWithTooltip(formatToFullDate(jobDetails.createdDate)));
  $("div#uploaded-by-value").append(createContentWithTooltip(formatUserName(jobDetails.userName)));
  $("div#id-value").append(createContentWithTooltip(jobDetails.id));
  if(jobDetails.events.length > 0){
    $("#job-details-list").append(buildJobEventTable(jobDetails.events));
  }
  else{
    $("#details-list-section").append(buildEmptyState());
  }
}

function removeJobInformation() {
  $("#job-details-dialog #dialog-title-text").empty();
  $("#job-details-dialog #dialog-title-text").attr("title", "");
  $("div#template-value span").remove();
  $("div#status-value span").remove();
  $("div#create-date-value span").remove();
  $("div#uploaded-by-value span").remove();
  $("div#id-value span").remove();
  $("#job-details-list tbody").empty();
  $("#events-empty-state").remove();
}

function setLoading() {
  $('#dialog-indicator').show();
  $("#job-details-info").hide();
  // stop closing dialog when click parent windows
  jobDetailsDialog.scrimClickAction = "";
  $("#close-btn").prop("disabled", true);
}

function removeLoading() {
  $('#dialog-indicator').hide();
  $("#job-details-info").show();
  // set closing dialog when click parent windows
  jobDetailsDialog.scrimClickAction = "close";
  $("#close-btn").prop("disabled", false);
}

init();
